<template>
  <div
    @click="openVideo"
    class="message__block message__block--multimedia message__block--with-background"
    role="button">
    <img
      v-if="hasThumbnail"
      :src="previewUrl"
      class="message__block-content message__block-content--as-background"/>
    <div class="message__block-button">
      <play-icon class="message__block-button-icon"/>
    </div>
    <div class="message__block-footer">
      <div class="message__block-footer-copy">
        {{ size | filesize }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  CAROUSEL_OPEN,
} from '@/eventTypes';
import EventBus from '@/eventBus';
import { getToken } from '@/vue-apollo';

export default {
  props: {
    message: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      token: getToken(),
    };
  },
  computed: {
    tokenQuery() {
      return `?token=${this.token}`;
    },
    multimedia() {
      return this.message.multimedia ?? {};
    },
    fullUrl() {
      return this.multimedia.file ? `${this.multimedia.file}${this.tokenQuery}` : '';
    },
    thumbUrl() {
      return this.multimedia.thumbnail ? `${this.multimedia.thumbnail}${this.tokenQuery}` : '';
    },
    size() {
      return Number.parseInt(this.multimedia.size ?? 0, 10);
    },
    hasThumbnail() {
      return this.thumbUrl.length > 0;
    },
    previewUrl() {
      return this.hasThumbnail ? this.thumbUrl : '';
    },
  },
  methods: {
    openVideo() {
      const payload = {
        url: this.fullUrl,
        type: 'video',
      };

      EventBus.$emit(CAROUSEL_OPEN, payload);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/chat/_message.scss';
</style>
